export const Cooking = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 265 210">
    <g transform="translate(0,210) scale(0.1,-0.1)">
      <path
        className="plate"
        d="M1219 1929 c-16 -16 -20 -30 -17 -59 l3 -37 -79 -12 c-268 -41 -514 -213 -652 -456 -51 -88 -99 -223 -109 -300 -13 -106 -15 -115 -33 -115 -24 0 -42 -19 -42 -46 0 -51 -34 -49 969 -51 515 -2 947 0 959 4 32 8 50 47 31 69 -7 9 -22 20 -33 24 -17 6 -22 23 -33 94 -16 115 -60 239 -120 339 -52 88 -185 232 -266 286 -117 79 -281 142 -410 157 -41 5 -55 10 -48 17 17 17 13 52 -10 81 -26 33 -79 36 -110 5z m266 -178 c293 -77 525 -296 614 -581 24 -75 44 -197 36 -210 -4 -6 -342 -10 -866 -10 l-859 0 0 38 c0 121 95 353 192 471 133 162 335 278 543 311 89 13 248 5 340 -19z"
      />
      <path
        className="plate"
        d="M915 1571 c-108 -49 -315 -266 -315 -330 0 -37 26 -22 65 37 54 82 189 212 258 247 34 18 56 36 55 44 -4 22 -19 22 -63 2z"
      />
    </g>
  </svg>
);
